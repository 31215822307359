import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MILLISECONDS_IN_A_SECOND } from "../utilities/constants";
import { TimeInterval, toMilli, dec } from "../utilities/utils";

const DELAY_TIME_SECONDS = 5;

export default function NotFound() {
  const [time, setTime] = useState(DELAY_TIME_SECONDS);
  const navigate = useNavigate();

  useEffect(() => {
    const timerId = setTimeout(
      () => {
        navigate(`/`);
      },
      toMilli(DELAY_TIME_SECONDS, TimeInterval.SEC)
    );

    return () => {
      clearTimeout(timerId);
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(dec(time));
    }, MILLISECONDS_IN_A_SECOND)

    return () => {
      clearInterval(interval);
    }
  }, [time])

  return (
    <main>
      <h1>Sorry! We couldn't find what you are looking for.</h1>
      <h2>You will be redirected in {time} seconds</h2>
    </main>
  );
}
