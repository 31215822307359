import React, { useEffect, useState } from 'react';
import * as Urls from '../assets/urls';
import { Strings } from '../assets/strings';
import { sendPost } from '../utilities/axiosClient';
import { useLocation } from 'react-router-dom';

const USERNAME_TAKEN = 'Username Already Taken';
const defaultUsernameInputClassnames = 'form-control';
const defaultSectionClassName = 'bg-white text-start';
const defaultConcernsClassnames = 'form-control';
const textAreaMaxChars = 1000;
const charWarningLength = 800;

export default function SignUp(props: any) {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [acceptedToS, setAcceptedToS] = useState(false);
  const [tierType, setTierType] = useState('');
  const [pricingAmount, setPricingAmount] = useState('');
  const [pricingPeriod, setPricingPeriod] = useState('');
  const [concerns, setConcerns] = useState('');
  
  const [remainingCharacters, setRemainingCharacters] = useState(100);
  const [submissionReceived, setSubmissionReceived] = useState(false);

  const [mainClassName, setMainClassName] = useState('');
  const [sectionClassName, setSectionClassName] = useState(defaultSectionClassName);
  const [usernameInputClassnames, setUsernameInputClassnames] = useState(defaultUsernameInputClassnames);
  const [concernsClassNames, setConcernsClassnames] = useState(defaultConcernsClassnames);

  const forwardedState = useLocation().state;

  const tiers: { [key: string]: string } = {
    '---': '',
    'Creator': ' - I have an idea',
    'Stormer': ' - I want to help brainstorm',
    'Co-Founder': ' - I want to co-found an idea',
    'Investor': ' - I want to invest in ideas'
  }

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'username':
        setUsername(value);
        break;
      case 'tierType':
        setTierType(value);
        break;
      case 'pricingAmount':
        setPricingAmount(value);
        break;
      case 'pricingPeriod':
        setPricingPeriod(value);
        break;
      case 'concerns':
        const charCount = value.length;
        if (charCount >= charWarningLength) {
          setConcernsClassnames(`${defaultConcernsClassnames} is-invalid`)
          setRemainingCharacters(textAreaMaxChars - charCount)
        } else if(charCount === charWarningLength - 1) {
          setConcernsClassnames(`${defaultConcernsClassnames}`)
          setRemainingCharacters(textAreaMaxChars)
        }

        setConcerns(value);
        break;
      case 'acceptedToS':
        setAcceptedToS(value);
        break;
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { succeeded, errorMessage } = await sendPost('/request-access/submit', {
      email,
      username,
      acceptedToS,
      tierType,
      pricingAmount,
      pricingPeriod,
      concerns
    })

    if (succeeded) {
      setSubmissionReceived(true);
    } else {
      handleSubmitError(errorMessage!);
    }
  }

  const handleSubmitError = (errorMessage: string) => {
    if (errorMessage === USERNAME_TAKEN) {
      setUsernameInputClassnames(`${defaultUsernameInputClassnames} is-invalid`)
    }
  }

  useEffect(() => {
    const forwardEmail = forwardedState?.forwardEmail;
    if (forwardEmail) {
      setEmail(forwardEmail);
    }
  }, [])

  useEffect(() => {
    if (!props?.isModal) {
      setMainClassName('bg-gradient-soundboard');
      setSectionClassName(`${defaultSectionClassName} border-top border-bottom`);
    }
  }, [])

  return (
    <main className={mainClassName}>
      <section className={sectionClassName}>
        {!submissionReceived &&
          <h2 className='text-center mt-4 mb-3 mx-2 px-4'>
            Fill out the form below to request access
          </h2>
        }
        {!submissionReceived &&
          <form className='mx-2 px-4' onSubmit={handleSubmit}>
            <div className='form-floating'>
              <input id='email' name='email' type='text' className='form-control' aria-describedby='emailHelp' placeholder='janedoe' value={email} onChange={handleInputChange} required />
              <label className='text-dark' style={{ zIndex: 20 }} htmlFor="email">{Strings.enterEmail}</label>
            </div>
            <div id='emailHelp' className='form-text text-start mb-3'>{Strings.emailCaption}</div>
            <div className='form-floating mb-2'>
              <input id='username' name='username' type='text' className={usernameInputClassnames} aria-label='Enter username' aria-describedby='validationServerUsernameFeedback' placeholder='brainstormer' onChange={handleInputChange} required />
              <label className='text-dark' htmlFor="email">{Strings.enterUsername}</label>
              <div id="validationServerUsernameFeedback" className="invalid-feedback" style={{ color: '#ff0000' }}>
                Username already taken, please choose another.
              </div>
            </div>
            <div>
              <label className='mt-2' htmlFor="tierType">{Strings.selectTier}</label>
              <select name="tierType" id="tierType" className="form-select mb-2" onChange={handleInputChange}>
                {Object.keys(tiers).map(tier => (<option value={tier} key={tier}>{`${tier}${tiers[tier]}`}</option>))}
              </select>
              <label className='mt-2' htmlFor="cost">{Strings.willingCost}</label>
              <div className="input-group mb-3" id='cost'>
                <span className="input-group-text">$</span>
                <input type="number" name="pricingAmount" className="form-control" placeholder="10" onChange={handleInputChange}></input>
                <select name="pricingPeriod" className="form-select" onChange={handleInputChange}>
                  <option value="year">Per Year</option>
                  <option value="month">Per Month</option>
                  <option value="lifetime">Lifetime</option>
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="concerns" className="form-label">What concerns do you have about the platform?</label>
              <textarea className={concernsClassNames} id="concerns" name='concerns' aria-describedby='validationConcernsLimitFeedback' maxLength={textAreaMaxChars} rows={8} onChange={handleInputChange}></textarea>
              <div id="validationConcernsLimitFeedback" className="invalid-feedback" style={{ color: '#ef0000' }}>
                Max length is 1000 characters; {remainingCharacters} remaning.
              </div>
            </div>
            <div className='form-check mb-3'>
              <input type='checkbox' name='acceptedToS' className='form-check-input' onChange={handleInputChange} required />
              By checking this box or using the platform, you agree to our <a className="text-dark" href={Urls.termsOfService} target="_blank" rel="noreferrer">{Strings.tos}</a> and <a className="text-dark" href={Urls.privacyPolicy} target="_blank" rel="noreferrer">{Strings.privacypolicy}</a>
            </div>
            <input type='submit' className='btn btn-dark mb-4' value='Submit' />
          </form>
        }
        {submissionReceived &&
          <h2 className='text-center my-3 mx-2 px-4'>
            Thank you for your interest in joining the community! We are working on getting access to as many people as possible and will email you soon. Stay tuned!
          </h2>
        }
      </section>
    </main>
  );
}
