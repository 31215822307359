import React from 'react';

export default function BaseModal(props: any) {

  return (
    <div className="modal fade" id={props.modalId} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">{props.title}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
          </div>
          <div className="modal-body">
            {props.content}
          </div>
        </div>
      </div>
    </div>
  );
}
