// Small words
const who = (cap = false) => cap ? 'Who' : 'who';
const how = (cap = false) => cap ? 'How' : 'hoe';
const why = (cap = false) => cap ? 'Why' : 'why';
const _for = (cap = false) => cap ? 'For' : 'for';
const about = (cap = false) => cap ? 'About' : 'about';
const us = (cap = false) => cap ? 'Us' : 'us';
const it = (cap = false) => cap ? 'It' : 'it';
const the = (cap = false) => cap ? 'The' : 'the';
const and = (cap = false) => cap ? 'And' : 'and';
const our = (cap = false) => cap ? 'Our' : 'our';
const to = (cap = false) => cap ? 'To' : 'to';
const your = (cap = false) => cap ? 'Your' : 'your';
const you = (cap = false) => cap ? 'You' : 'you';
const a = (cap = false) => cap ? 'A' : 'a';
const we = (cap = false) => cap ? 'We' : 'we';
const will = (cap = false) => cap ? 'Will' : 'will';
const weWill = (cap = false) => cap ? 'We\'ll' : 'we\'ll';
const _with = (cap = false) => cap ? 'With' : 'with';

// individual words
export const community = (cap = false) => cap ? 'Community' : 'community';
const works = (cap = false) => cap ? 'Works' : 'works';
const team = (cap = false) => cap ? 'Team' : 'team';
const guide = (cap = false) => cap ? 'Guide' : 'guide';
export const faqs = (cap = false) => cap ? 'FAQs' : 'faqs';
const story = (cap = false) => cap ? 'Story' : 'story';
const welcome = (cap = false) => cap ? 'Welcome' : 'welcome';
const enter = (cap = false) => cap ? 'Enter' : 'enter';
const email = (cap = false) => cap ? 'Email' : 'email';
const username = (cap = false) => cap ? 'Username' : 'username';
const never = (cap = false) => cap ? 'Never' : 'never';
const share = (cap = false) => cap ? 'Share' : 'share';

// General
export const soundboard = 'Soundboard';

// Header and Footer
export const signIn = 'Sign In';
export const requestAccess = 'Request Access';
const stormers = (cap = false) => cap ? 'Stormers' : 'stormers';
const companies = (cap = false) => cap ? 'Companies' : 'companies';
const creators = (cap = false) => cap ? 'Creators' : 'creators';
export const whySoundboard = `${why(true)} ${soundboard}`;
export const howItWorks = `${how(true)} ${it(true)} ${works(true)}`;
export const tos = 'Terms of Service';
export const privacypolicy = 'Privacy Policy';
export const copyright = `© 2021 ${soundboard}X Inc., All rights reserved.`;
export const accessCommunity = `Access the ${community(true)}`;
export const accessTagline = 'Start brainstorming ideas and build the future.';
export const forStormersHeading = `${_for(true)} ${stormers(true)}`;
export const forCreatorsHeading = `${_for(true)} ${creators(true)}`;
export const aboutUsHeading = `${about(true)} ${us(true)}`;
export const press = (cap = false) => cap ? 'Press' : 'press';
export const careers = (cap = false) => cap ? 'Careers' : 'careers';
export const guideAndFAQS = `${guide(true)} ${and()} ${faqs(true)}`;
export const theTeam = `${the(true)} ${team(true)}`;
export const ourStory = `${our(true)} ${story(true)}`;
export const welcomeTo = `${welcome(true)} ${to()} ${soundboard}`;

// Main Landing
export const taglineFirst = 'Brainstorm Ideas.';
export const taglineSecond = 'Build the Future.';
export const landingLead = 'Bringing together creators and entrepreneurs from all backgrounds to build the future they envision. The Soundboard community is the place for definite optimists who want high quality feedback on their ideas or experienced individuals looking to help the next entrepreneur.';
export const learnMore = 'Learn More';

// Landing Creators Section
export const creatorSectionHeading = 'Create an idea';
export const creatorSectionParagraph = 'Take your idea to the next level with direct guidance from the Soundboard team and a community of experts.';
export const becomeCreator = 'Become a Creator';

// Landing Stormers Section
export const stormerSectionHeading = 'Brainstorm ideas';
export const stormerSectionParagraph = 'Bringing together creators and entrepreneurs from all backgrounds to build the future they envision. The Soundboard community is the place for definite optimists who want high quality feedback on their ideas or experienced individuals looking to help the next entrepreneur.';
export const becomeStormer = 'Become a Stormer';

// Landing Mission Section
export const missionSectionHeading = 'Join the community';
export const missionSectionParagraph = 'Bringing together creators and entrepreneurs from all backgrounds to build the future they envision. The Soundboard community is the place for definite optimists who want high quality feedback on their ideas or experienced individuals looking to help the next entrepreneur.';

// Signup
export const enterEmail = `${enter(true)} ${your()} ${email()}`;
export const enterUsername = `${enter(true)} ${a()} ${username()}`;
export const emailCaption = `We'll never share your email with anyone.`;
export const selectTier = `Please choose how you would use the platform`;
export const willingCost = `Please select how much you would be willing to pay for this platform`;