import React from 'react';
import { NavLink } from 'react-router-dom';
import { RouteNames, Strings } from '../assets/strings';
import * as Urls from '../assets/urls';
import brain from '../assets/images/brain_lightning_raw.svg';
import NavDropDown from './navDropDown';

export default function Header() {
  const navDropDowns = [
    {
      navLabel: Strings.forCreatorsHeading,
      navLinks: [
        {
          to: Urls.whySoundboardCreators,
          label: Strings.whySoundboard
        },
        {
          to: Urls.howItWorksCreators,
          label: Strings.howItWorks
        },
        {
          to: Urls.communityCreators,
          label: Strings.community(true)
        },
        {
          to: Urls.guidesAndFAQsCreators,
          label: Strings.faqs(true)
        }
      ]
    },
    {
      navLabel: Strings.forStormersHeading,
      navLinks: [
        {
          to: Urls.whySoundboardStormers,
          label: Strings.whySoundboard
        },
        {
          to: Urls.howItWorksStormers,
          label: Strings.howItWorks
        },
        {
          to: Urls.guidesAndFAQsStormers,
          label: Strings.faqs(true)
        }
      ]
    }
  ];

  return (
    <div className='mb-auto'>
      <nav className='navbar navbar-light bg-light border-bottom navbar-expand-md'>
        <div className='container-fluid mx-md-5'>
          <NavLink
            to={RouteNames.Home}
            className='navbar-brand'
          >
            <img src={brain} className='App-logo me-2' alt='soundboard logo' />
            <span className='align-middle'>{Strings.soundboard}</span>
          </NavLink>
          <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse pt-1" id="navbarHeader">
            <ul className='w-100 navbar-nav'>
              {navDropDowns.map(dropDown => (
                <NavDropDown
                  key={dropDown.navLabel}
                  navLabel={dropDown.navLabel}
                  navLinks={dropDown.navLinks}
                />
              ))}
              <a
                href={Urls.ourStory}
                className='nav-link me-md-auto'
                target='_blank'
                rel="noreferrer"
              >
                {Strings.aboutUsHeading}
              </a>
              <NavLink
                to={RouteNames.SignUp}
                className='btn btn-primary btn-big'
              >
                {Strings.requestAccess}
              </NavLink>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}
