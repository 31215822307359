import React from 'react';
import './assets/styles/App.css';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';


function App() {
  return (
    <div className='d-flex h-100 flex-column mx-auto'>
      {/* App should contain Header, children pages, menu, footer */}
      {/* Header, menu, footer are common across children components */}
      <Header />
      <Outlet />
      <Footer />
      <ScrollRestoration />
    </div>
  );
}

export default App;
