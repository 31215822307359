import React, { useState } from 'react';
import { RouteNames, Strings } from '../assets/strings';
import * as Urls from '../assets/urls';
import '../assets/styles/landing/footer.css'
import { useNavigate } from 'react-router-dom';

export default function Footer() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (event: any) => {
    setEmail(event.target.value);
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    navigate(RouteNames.SignUp, { state: { forwardEmail: email } })
  }

  return (
    <div className='container-fluid bg-dark mt-auto text-center'>
      <footer className="row justify-content-center pt-4 pb-2 mx-5">
        <div className='col-12 col-md-3 col-lg-2 footer-section'>
          <h5 className='text-white'>{Strings.forCreatorsHeading}</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2"><a target="_blank" rel="noreferrer" style={{ color: '#d3d6dc' }} href={Urls.whySoundboardCreators} className="nav-link p-0">{Strings.whySoundboard}</a></li>
            <li className="nav-item mb-2"><a target="_blank" rel="noreferrer" style={{ color: '#d3d6dc' }} href={Urls.howItWorksCreators} className="nav-link p-0">{Strings.howItWorks}</a></li>
            <li className="nav-item mb-2"><a target="_blank" rel="noreferrer" style={{ color: '#d3d6dc' }} href={Urls.communityCreators} className="nav-link p-0">{Strings.community(true)}</a></li>
            <li className="nav-item mb-2"><a target="_blank" rel="noreferrer" style={{ color: '#d3d6dc' }} href={Urls.guidesAndFAQsCreators} className="nav-link p-0">{Strings.faqs(true)}</a></li>
          </ul>
        </div>
        <div className='col-12 col-md-3 col-lg-2 footer-section'>
          <h5 className='text-white'>{Strings.forStormersHeading}</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2"><a target="_blank" rel="noreferrer" style={{ color: '#d3d6dc' }} href={Urls.whySoundboardStormers} className="nav-link p-0">{Strings.whySoundboard}</a></li>
            <li className="nav-item mb-2"><a target="_blank" rel="noreferrer" style={{ color: '#d3d6dc' }} href={Urls.howItWorksStormers} className="nav-link p-0">{Strings.howItWorks}</a></li>
            <li className="nav-item mb-2"><a target="_blank" rel="noreferrer" style={{ color: '#d3d6dc' }} href={Urls.guidesAndFAQsStormers} className="nav-link p-0">{Strings.faqs(true)}</a></li>
          </ul>
        </div>
        <div className='col-12 col-md-3 col-lg-2 me-lg-5 footer-section'>
          <h5 className='text-white'>{Strings.aboutUsHeading}</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2"><a target="_blank" rel="noreferrer" style={{ color: '#d3d6dc' }} href={Urls.ourStory} className="nav-link p-0">{Strings.ourStory}</a></li>
            {/* <li className="nav-item mb-2"><a style={{color: '#d3d6dc'}} href="#" className="nav-link p-0">{Strings.theTeam}</a></li>
        <li className="nav-item mb-2"><a style={{color: '#d3d6dc'}} href="#" className="nav-link p-0">{Strings.press()}</a></li>
        <li className="nav-item mb-2"><a style={{color: '#d3d6dc'}} href="#" className="nav-link p-0">{Strings.careers()}</a></li> */}
          </ul>
        </div>
        <div className='col-12 col-md-3 footer-form'>
          <form className='text-left pt-2' onSubmit={handleSubmit}>
            <h5 className='text-white'>{Strings.accessCommunity}</h5>
            <p style={{ color: '#d3d6dc' }}>{Strings.accessTagline}</p>
            <div className="input-group">
              <input id="request-access-email" type="text" className="form-control" placeholder="janedoe@gmail.com" aria-label='Enter your email' onChange={handleInputChange} />
              <button className="btn btn-primary" type="submit">{Strings.requestAccess}</button>
            </div>
          </form>
        </div>
      </footer>
      <div className='mt-1'>
        <p className='land-foot-copyright'>
          {Strings.soundboard} {Strings.copyright} <a className='land-foot-link' href={Urls.termsOfService} target="_blank" rel="noreferrer">{Strings.tos}</a> | <a className='land-foot-link' href={Urls.privacyPolicy} target="_blank" rel="noreferrer">{Strings.privacypolicy}</a>
        </p>
        <p className='land-foot-copyright'>Images by <a className='land-foot-link' target="_blank" rel="noreferrer" href={Urls.imageAttribution}>vectorjuice</a> on Freepik</p>
      </div>
    </div>
  )
}
