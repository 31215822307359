import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import './assets/styles/index.css';
import App from './App';
import {
  Landing,
  SignUp,
  NotFound
} from './pages';
import { RouteNames } from './assets/strings';

const router = createBrowserRouter([
  {
    path: RouteNames.Home,
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        errorElement: <NotFound />,
        children: [
          { index: true, element: <Landing /> },
          {
            path: RouteNames.SignUp,
            element: <SignUp isModal={false} />
          }
        ]
      }
    ]
  }
])

ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
