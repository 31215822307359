import * as Constants from './constants';

enum TimeInterval {
  MIL,
  SEC,
  MIN,
  HR,
  D,
  Y
}

const toMilli = (time: number, granularity: TimeInterval): number => {
  switch (granularity) {
    case TimeInterval.MIL:
      return time;
    case TimeInterval.SEC:
      return time * Constants.MILLISECONDS_IN_A_SECOND;
    case TimeInterval.MIN:
      return time * Constants.MILLISECONDS_IN_A_MINUTE;
    case TimeInterval.HR:
      return time * Constants.MILLISECONDS_IN_A_HOUR;
    case TimeInterval.D:
      return time * Constants.MILLISECONDS_IN_A_DAY;
    case TimeInterval.Y:
      return time * Constants.MILLISECONDS_IN_A_YEAR;
  }
}

const fromMilli = (milliseconds: number, granularity: TimeInterval): number => {
  switch (granularity) {
    case TimeInterval.MIL:
      return milliseconds;
    case TimeInterval.SEC:
      return milliseconds / Constants.MILLISECONDS_IN_A_SECOND;
    case TimeInterval.MIN:
      return milliseconds / Constants.MILLISECONDS_IN_A_MINUTE;
    case TimeInterval.HR:
      return milliseconds / Constants.MILLISECONDS_IN_A_HOUR;
    case TimeInterval.D:
      return milliseconds / Constants.MILLISECONDS_IN_A_DAY;
    case TimeInterval.Y:
      return milliseconds / Constants.MILLISECONDS_IN_A_YEAR;
  }
}

const dec = (num: number): number => {
  return --num;
}

const inc = (num: number): number => {
  return ++num;
}

const capitalize = (word: string): string => {
  return word[0].toUpperCase() + word.substring(1).toLowerCase()
}

export {
  TimeInterval,
  toMilli,
  fromMilli,
  dec,
  inc,
  capitalize
}