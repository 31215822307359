import axios from 'axios';

type PostResponse = {
  succeeded: boolean;
  data?: any;
  errorMessage?: string;
}

const sendPost = async (path: string, data: { [key: string]: any }, config?: { [key: string]: any }): Promise<PostResponse> => {
  try {
    const response = await axios.post(path, data, config);
    return { succeeded: true, data: response.data };
  } catch (error: any) {
    logError(error);
    return { succeeded: false, errorMessage: error.response.data }
  };
}

const logError = (error: any) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log(error.config);
}


export {
  sendPost
}
