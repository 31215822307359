import React, { Fragment } from 'react';

export default function LandingSection(props: any) {
  const {
    sectionId,
    imageSrc,
    sectionHeading,
    sectionParagraph,
    firstButtonText,
    firstButtonOnClick,
    secondButtonText,
    secondButtonOnClick,
    swapOrder
  } = props.data;

  return (
    <Fragment>
      <div id={sectionId} className='row mx-0 row-cols-md-2'>
        <div className={`d-flex align-middle justify-content-center ${swapOrder ? 'order-md-2' : ''}`}>
          <img className='img-fluid' src={imageSrc} alt=""/>
        </div>
        <div className={`px-3 p-md-5 mt-sm-4 mt-md-0 ${swapOrder ? 'order-md-1' : ''}`}>
          <h2 className='ms-3 my-3 mb-lg-4 mx-lg-3'>{sectionHeading}</h2>
          <p className='ms-3 w-75'>
            {sectionParagraph}
          </p>
          <div className='ms-3 mb-3'>
            <button className='btn btn-dark me-1' onClick={firstButtonOnClick}>{firstButtonText}</button>
            { secondButtonText && <button id="become-a-stormer" className='btn btn-dark' onClick={secondButtonOnClick}>{secondButtonText}</button> }
          </div>
        </div>
      </div>
    </Fragment>
  )
}