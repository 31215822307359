export const whySoundboardCreators = 'https://soundboardx.notion.site/Why-Soundboard-11293d64124348448385a3b746409056';
export const howItWorksCreators = 'https://soundboardx.notion.site/How-it-Works-a10255893ff145cb914ff3a15a459263';
export const communityCreators = 'https://soundboardx.notion.site/Community-93493c88aa7d4a0995a0998b01178916';
export const guidesAndFAQsCreators = 'https://soundboardx.notion.site/Guides-and-FAQs-a9f31a495ce540b386f3b9f6352b2784';

export const whySoundboardStormers = 'https://soundboardx.notion.site/Why-Soundboard-32c6bd3f76a54d0f9f3319b2716c9e56';
export const howItWorksStormers = 'https://soundboardx.notion.site/How-it-Works-92e537b3176d42bea9e21aa700cd4778';
export const guidesAndFAQsStormers = 'https://soundboardx.notion.site/Guides-and-FAQs-cfdb684f9a964de6ac020d5f3fd38882';

export const ourStory = 'https://soundboardx.notion.site/Our-Story-e56b316a1b4b4e7680d3a20e2de34dcb';
export const theTeam = 'https://soundboardx.notion.site/a749492259c14819a49e5f5f86cc3899?v=9f53ea0b685149b29a40c45a4e0e0402';
export const privacyPolicy = 'https://soundboardx.notion.site/Privacy-Policy-f5c4c67a00814d14a72ff36449a52e24';
export const termsOfService = 'https://soundboardx.notion.site/Terms-of-Service-d067683c06c1438c86acd605efbf57cc';

export const imageAttribution = 'https://www.freepik.com/author/vectorjuice'