import React, { Fragment } from 'react';
import Modal from 'bootstrap/js/dist/modal'
import { RouteNames, Strings } from '../assets/strings';
import '../assets/styles/landing/landing.css'
import { useNavigate } from 'react-router-dom';
import SignUp from './signup';
import { BaseModal, LandingSection } from '../components';
import createImage from '../assets/images/generate-idea-figma.svg';
import brainstormImage from '../assets/images/brainstorm-together.svg';
import communityImage from '../assets/images/build-together.svg';

export default function Landing() {
  const navigate = useNavigate();
  const modalId = "landingRequestAccessModal";

  const handleRequestAccessClick = (event: any) => {
    if (window.innerWidth <= 500) {
      navigate(RouteNames.SignUp);
    } else {
      const modal = new Modal(document.getElementById(modalId)!);
      modal.show();
    }
  }

  const handleCloseModal = () => {
    const modal = new Modal(document.getElementById(modalId)!);
    modal.hide();
  }

  const sectionDatas = [
    {
      sectionId: 'creatorSection',
      imageSrc: createImage,
      sectionHeading: Strings.creatorSectionHeading,
      sectionParagraph: Strings.creatorSectionParagraph,
      firstButtonText: Strings.learnMore,
      firstButtonOnClick: () => {},
      secondButtonText: Strings.becomeCreator,
      secondButtonOnClick: handleRequestAccessClick,
      swapOrder: true
    },
    {
      sectionId: 'stormerSection',
      imageSrc: brainstormImage,
      sectionHeading: Strings.stormerSectionHeading,
      sectionParagraph: Strings.stormerSectionParagraph,
      firstButtonText: Strings.learnMore,
      firstButtonOnClick: () => {},
      secondButtonText: Strings.becomeStormer,
      secondButtonOnClick: handleRequestAccessClick,
      swapOrder: false
    },
    {
      sectionId: 'communitySection',
      imageSrc: communityImage,
      sectionHeading: Strings.missionSectionHeading,
      sectionParagraph: Strings.missionSectionParagraph,
      firstButtonText: Strings.requestAccess,
      firstButtonOnClick: handleRequestAccessClick,
      swapOrder: true
    },
  ]

  return (
    <Fragment>
      <main className='bg-gradient-soundboard py-5'>
        <section className='text-white'>
          <div className='my-2 mx-2 px-4'>
            <h1 className=''>{Strings.taglineFirst}</h1>
            <h1 className=''>{Strings.taglineSecond}</h1>
            <p className='lead fs-1'>
              {Strings.landingLead}
            </p>
          </div>
        </section>
      </main>
      <div className='mx-auto'>
        {sectionDatas.map(
            sectionData => <LandingSection key={sectionData.sectionId} data={sectionData}/>
        )}
      </div>
      <BaseModal modalId={modalId} title={Strings.requestAccess} onClose={handleCloseModal} content={<SignUp isModal={true} />} />
    </Fragment>
  );
}
