import React from 'react';

type NavLinkItem = {
  to: string,
  label: string
}

type NavDropDownProps = {
  navLabel: string,
  navLinks: NavLinkItem[]
}

export default function NavDropDown(props: NavDropDownProps) {
  return (
    <li className='nav-item dropdown'>
      <a className="nav-link" id="navbarDropdown" role="button" href='#' data-bs-toggle="dropdown" aria-expanded="false">
        {props.navLabel}
      </a>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
        {props.navLinks.map((navLink: NavLinkItem) => (
          <a
            key={navLink.label}
            href={navLink.to}
            className='dropdown-item'
            target="_blank"
            rel="noreferrer"
          >
            {navLink.label}
          </a>
        ))}
      </ul>
    </li>
  )
}
